<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    
    <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <!-- Organization -->
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          
        >
          <organization-selector
            :organization="templateMail.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId, organization) => {
                templateMail.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!templateMail.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

    
    
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TEMPLATES_MAILS.TITLE')}`"
        :placeholder="$t('TEMPLATES_MAILS.TITLE')"
        v-model="templateMail.title"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.title" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TEMPLATES_MAILS.SUBJECT')}`"
        :placeholder="$t('TEMPLATES_MAILS.SUBJECT')"
        v-model="templateMail.subject"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.subject" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TEMPLATES_MAILS.FROM')}`"
        :placeholder="$t('TEMPLATES_MAILS.FROM')"
        v-model="templateMail.from"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.from" />
    </div>
    <div class="form-wrapper full">
        <!-- TO -->
        <tags-input
          :label="`${$t('TEMPLATES_MAILS.TO')}`"
          :placeholder="$t('TEMPLATES_MAILS.TO')"
          v-model="templateMail.recipients"
          @change="(mails) => {selectedUsersTo = mails}"
        />
        
        <validation-error :errors="apiValidationErrors.recipients" />
    </div>
    <div class="form-wrapper full">
        <!-- CC -->
        <tags-input
          :label="`${$t('TEMPLATES_MAILS.CC')}`"
          :placeholder="$t('TEMPLATES_MAILS.CC')"
          v-model="templateMail.cc"
          @change="(mails) => {selectedUsersCc = mails}"
        />
        <validation-error :errors="apiValidationErrors.cc" />
    </div>
    <div class="form-wrapper full">
        <!-- BCC -->
        <tags-input
          :label="`${$t('TEMPLATES_MAILS.BCC')}`"
          :placeholder="$t('TEMPLATES_MAILS.BCC')"
          v-model="templateMail.bcc"
          @change="(mails) => {selectedUsersBcc = mails}"
        />
        <validation-error :errors="apiValidationErrors.bcc" />
    </div>
    <div class="form-wrapper full">
        <!-- Type -->
        <base-input
          :label="`${$t('TEMPLATES_MAILS.TYPE')} (*)`"
          :placeholder="$t('TEMPLATES_MAILS.TYPE')"
        >
          <types-mail-selector
            :typesMail="templateMail.typesMail.id"
            :filterable="true"
            :showAll="false"
            :disabled="!templateMail.organization.id"
            :filterOrganization="templateMail.organization.id"
            @typesMailChanged="
              (typesMailId, typesMail) => {
                templateMail.typesMail.id = typesMailId;
                getTypeVariables(typesMailId);
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.typesMail" />
      </div>
    <div class="form-wrapper full">
      <div class="type-variables" v-for="(variable, key) in typeVariables" style="display: block;margin-bottom: 5px;">
        <span><b>[<span :key="key">{{key}}</span>] : </b></span><span style="font-size:12px;">{{variable}}</span>
      </div>
    </div>
    <div class="form-wrapper full">
      <base-input
        :label="$t('TEMPLATES_MAILS.CONTENT')"
        :placeholder="$t('TEMPLATES_MAILS.CONTENT')"
      >
        <html-editor v-model="templateMail.content" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.content" />
    </div>

    
    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          templateMail.id ? $t("TEMPLATES_MAILS.EDIT_TEMPLATE_MAIL") : $t("TEMPLATES_MAILS.ADD_TEMPLATE_MAIL")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
  import Vue from 'vue';
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import TagsInput from "@/components/Inputs/TagsInput.vue";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import UsersMailsSelector from "@/components/UsersMailsSelector.vue";
import TypesMailSelector from "@/components/TypesMailSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    TagsInput,
    ValidationError,
    OrganizationSelector,
    UsersMailsSelector,
    TypesMailSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["templateMailData", "formErrors", "loading"],

  data() {
    let templateMailData = cloneDeep(this.templateMailData);
    templateMailData = this.$fillUserOrganizationData(templateMailData);
    return {
      templateMail: templateMailData,
      typeVariables: [],
      permissions: [],
      permissionsModel: {},
      models: models,
      selectedUsersTo: templateMailData.recipients ?? [],
      selectedUsersCc: templateMailData.cc ?? [],
      selectedUsersBcc: templateMailData.bcc ?? [],
    };
  },

  created() {
    this.selectedUsersTo = this.templateMail.recipients ?? [];
    this.selectedUsersCc = this.templateMail.cc ?? [];
    this.selectedUsersBcc = this.templateMail.bcc ?? [];
  },

  mounted() {
  },

  methods: {
    async handleSubmit() {
      const usersTo = this.selectedUsersTo.map((item) => {
        return item.item ? item.item : item;
      });
      const usersCc = this.selectedUsersCc.map((item) => {
        return item.item ? item.item : item;
      });
      const usersBcc = this.selectedUsersBcc.map((item) => {
        return item.item ? item.item : item;
      });
      this.templateMail.recipients = usersTo;
      this.templateMail.cc = usersCc;
      this.templateMail.bcc = usersBcc;
      let templateMailData = cloneDeep(this.templateMail);
      templateMailData = this.$fillUserOrganizationData(templateMailData);
      this.$emit("templateMailSubmitted", templateMailData);
    },
    async getTypeVariables(id) {
      try {
        await this.$store.dispatch("typesMails/get", id);
        let typesMail = this.$store.getters["typesMails/typesMail"];
        this.typeVariables = typesMail.variables;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    templateMailData(templateMailData) {
      if (templateMailData) {
        this.templateMail = {
          ...this.templateMail,
          ...cloneDeep(templateMailData),
        };
      }
    },
  },
};
</script>
